<template>
  <div class="task">
    <div
      class="box"
      id="imgBox"
    >
      <div
        class="item-box"
        v-for="(item,index) in contentList"
        :key="index"
      >
        <div class="line"></div>
        <div class="circle-big">
          <div class="circle-small">{{index + 1}}</div>
        </div>

        <div class="text-box">
          <div v-html="item.title"></div>
          <div
            class="qrcode-box"
            v-if="item.img"
          >
            <div class="first-top">
              <div
                class="first-top-text"
                v-html="item.first"
              ></div>
              <div>
                <img
                  class="first-qrcode-img"
                  :src="item.img"
                  alt=""
                >
              </div>
            </div>
            <div class="cut-off-rule">
              <div></div>
            </div>
            <div
              class="second-bottom"
              v-html="item.second"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <loading :loading="loading"></loading>
  </div>

</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      loading: false,
      contentList: [
        {
          title: '欢迎参加 名企内推直通车计划',
          img: 'https://ceshiren.com/uploads/default/original/3X/3/1/3113161a2ea392753b559ba77099ee2135651e82.jpeg',
          first: '第一步<br/> <span>扫码添加小助手</span>',
          second: '第二步<br/> <span>回复「直通车」获取专属报名链接<br/>及班级群入群信息</span>'
        },
        { title: '点击专属报名链接<br />报名参加名企内推直通车计划', },
        { title: '加入班级群,开启学习之旅', },
      ]
    }
  },
  created () {
    this.getData()
  },
  mounted () {
  },
  methods: {
    //获取数据
    getData () {
      this.loading = true
      // axios.get(`${location.origin}/backend/config/${this.$route.query.config}`)
      axios.get(`https://dev.design.ceba.ceshiren.com/backend/config/${this.$route.query.config}`)
        .then(res => {
          console.log(res);
          if (res.data.data) {
            this.contentList = res.data.data.contentList

            if (res.data.data.css) {
              this.createCssLink(res.data.data.css)
            } else {
              // this.createCssLink('default')
            }
          }

        })
    },
    //避免重复载入 
    removeRemoteCss () {
      const linkList = document.querySelectorAll('#remote_css')
      console.log(linkList);
      for (let i = 0; i < linkList.length; i++) {
        linkList[i].parentNode.removeChild(linkList[i]);
      }
    },

    // 创建link标签引用远程css
    createCssLink (css_name) {
      this.removeRemoteCss()
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.id = 'remote_css'
      const cssPath = `https://dev.design.ceba.ceshiren.com/backend/css/${css_name}.css`
      link.href = cssPath
      document.head.appendChild(link)
      console.log('css加载完成');
      // setTimeout(() => { this.domtoimg() }, 1500)
      setTimeout(() => {
        this.$toImage.domtoimg(document.getElementById('imgBox'))
        this.loading = false
      }, 1500)

    },
  }
}
</script>

<style scoped lang="less">
// .task {
//   display: flex;
//   justify-content: center;
// }
// #imgBox {
//   width: 100%;
//   max-width: 420px;
//   background-color: #fff;
//   padding-left: 5px;
//   padding-top: 10px;
// }
// .item-box {
//   position: relative;
//   padding-bottom: 20px;
// }
// .circle-big {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   left: -1px;
//   width: 36px;
//   height: 50px;
//   position: absolute;
//   background-color: #fff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .circle-small {
//   width: 26px;
//   height: 26px;
//   background-color: #f18d32;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #fff;
// }

// .line {
//   position: absolute;
//   left: 17px;
//   height: 100%;
//   border-left: 1px solid #aeaeae;
// }
// .text-box {
//   position: relative;
//   top: -3px;
//   word-break: break-all;
//   padding: 15px 10px 0 40px; /* 上右下左的内边距 */
// }
// #imgBox div:last-child .line {
//   display: none;
// }
// #imgBox div:last-child .text-box {
// }
// .qrcode-box {
//   background-color: #f18d32;
//   margin-top: 10px;
//   border-radius: 10px;
// }
// .first-top {
//   display: flex;
//   justify-content: space-between;
//   padding: 10px 30px 10px 20px;
// }
// .second-bottom {
//   padding: 10px 30px 10px 20px;
// }
// .first-top-text {
//   padding-top: 25px;
// }
// .qrcode-box {
//   color: #f5eae0;
// }

// .first-qrcode-img {
//   width: 100px;
//   height: 100px;
//   padding: 15px 0;
// }
// .cut-off-rule {
//   width: 100%;
//   padding: 0 30px 0 20px;
//   margin: 0 auto;
// }
// .cut-off-rule div {
//   height: 1px;
//   background-color: #fbe7d5;
// }
</style>